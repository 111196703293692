/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import ButtonAlt from 'components/elements/ButtonAlt'
import CustomLink from 'components/shared/CustomLink'
import ButtonDefault from './elements/ButtonDefault'

const Sermon = styled.div`
  position: relative;
`
const Item = styled.div`
  position: relative;
`

const SermonArchive = () => {
  const { sermons } = useStaticQuery(graphql`
    {
      sermons: allWordpressWpPrekenarchief(
        sort: { fields: date, order: DESC }
        limit: 2
      ) {
        edges {
          node {
            title
            path
            acf {
              sermon {
                time
                predecessor
                sermonURL: sermon_url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Sermon className="px-3 px-lg-5 mx-3">
      {sermons.edges.map(
        (
          {
            node: {
              title,
              path,
              acf: { sermon },
            },
          },
          index
        ) => (
          // eslint-disable-next-line react/no-array-index-key
          <Item key={index} className="mb-4">
            <p className="font-weight-bold mb-0">
              <CustomLink to={path}>{title}</CustomLink>
            </p>
            {sermon.map(({ time, predecessor, sermonURL }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index} className="mb-0 pl-3">
                <span>{time}:</span> <span>{predecessor}</span>
              </p>
            ))}
            <p className="pl-3 mt-2">
              <ButtonAlt to={path}>Beluister preek</ButtonAlt>
            </p>
          </Item>
        )
      )}

      <ButtonDefault to="/kerkdiensten/prekenarchief" className="mt-4">
        Meer preken
      </ButtonDefault>
    </Sermon>
  )
}

export default SermonArchive
