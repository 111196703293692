/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ImageBackground from 'components/shared/ImageBackground'

const Hero = styled(ImageBackground)`
  display: flex;
  align-items: flex-end;

  @media (min-width: 992px) {
    min-height: 375px;
  }

  @media (max-width: 991px) {
    min-height: 150px;
  }
`

const Content = styled(ParseContent)`
  font-family: ${props=> props.theme.font.family.secondary};
  font-style: italic;
  color: ${props => props.theme.color.text.light};

  & h1 {
    font-size: ${props => props.theme.font.size.xl};
    line-height: ${props => props.theme.font.size.xxl};
    background: ${props => props.theme.color.face.secondary};
      padding: 10px;

    @media (max-width: 767px) {
      font-size: ${props => props.theme.font.size.l};
      line-height: 35px;
      background: ${props => props.theme.color.face.secondary};
      padding: 10px;

      & span {
        padding: 0 !important;
      }
    }

    & span {
      background: ${props => props.theme.color.face.secondary};
      padding: 10px;
    }
  }
`

const HeroDefault = ({ fields }) => (
  <Hero src={fields.image} aboveFold>
    <div className="container py-5 px-lg-0 mb-3">
      <div className="col-lg-7 p-0">
        <Content content={fields.description} />
      </div>
    </div>
  </Hero>
)

export default HeroDefault