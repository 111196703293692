/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import ButtonDefault from 'components/elements/ButtonDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import Agenda from 'components/Agenda'
import SermonArchive from 'components/SermonArchive'
import AgendaBlock from 'components/AgendaBlock'

const Block = styled.div`
  position: relative;
`

const BlockGrey = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.face.grey};
`

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <HeroDefault fields={acf.banner} />

      <section className="home-intro">
        <div className="container py-lg-5">
          <div className="row py-lg-5">
            <Block className="col-lg-4 py-5">
              <img src={acf.about.icon.localFile.publicURL} alt="" className="d-block mx-auto mb-4" />
              <ParseContent content={acf.about.title} className="mb-5" />
              <ParseContent content={acf.about.description} />
            </Block>
            <Block className="col-lg-4 px-3">
              <AgendaBlock />
            </Block>
            <Block className="col-lg-4 py-5">
              <img src={acf.preachArchive.icon.localFile.publicURL} alt="" className="d-block mx-auto mb-4" />
              <ParseContent content={acf.preachArchive.title} className="mb-5" />
              <SermonArchive />
            </Block>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HomeTemplate